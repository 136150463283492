import {Layout} from "../components/Layout";
import React, {useEffect} from 'react';
import {graphql} from "gatsby";
import {PageMainColumnWrapper} from "../components/PageMainColumnWrapper";
import {BackgroundLine} from "../components/BackgroundLine";
import {CareersJobs} from "../components/CareersJobs";
import {Typography} from "../components/Typography";
import {initConfetti} from "../functions/initConfetti";
import {BlockTitle} from "../components/BlockTitle";

const styles = require('./ggsPeopleRocks.module.scss');

export const query = graphql`
    query GGSPeopleRocks($locale: String!) {
        jobs: allContentfulJobOffer(filter: {node_locale: {eq: $locale}}) {
            edges {
                node {
                    name
                    url
                    description {
                        childMarkdownRemark {
                            html
                        }
                    }
                }
            }
        }
    }
`;

export default function GSSPeopleRocksPage({data, pageContext}) {
    useEffect(() => {
        initConfetti()
    }, []);

    const onImageClick = () => {
        window.confetti && window.confetti({
            spread: 120,
            particleCount: 100,
        });
    };

    const people = pageContext.people;

    return <Layout services={pageContext.services}
                   oldServices={pageContext.oldServices}
                   noIndex={true}
                   linkableSubServices={pageContext.linkableSubServices}
                   title={'GGS People Rocks'}
                   jobsCount={pageContext.jobsCount}
                   companyData={pageContext.companyData}
                   background={<>
                       <BackgroundLine mobileOn={false}/>
                       <BackgroundLine position={"flexion"}/>
                   </>}
                   lang={pageContext.lang}>
        <PageMainColumnWrapper>
            <BlockTitle className={styles.mainTitle}>GGS People Rocks!</BlockTitle>
            <div className={styles.peopleWrapper}>
                {
                    people.map((data, index) => {
                        return <People
                            title={data.name}
                            description={data.content}
                            image={data.avatar}
                            onImageClick={onImageClick}
                            key={index}
                        />
                    })
                }
            </div>
        </PageMainColumnWrapper>
        <CareersJobs jobs={data.jobs.edges.map(x => x.node)}
                     title="Still scrolling?"
                     content={{childMarkdownRemark: {html: "We are still hiring, check this out:"}}}
        />
    </Layout>
}


function People({title, description, image, onImageClick}) {
    return <article className={styles.people}>
        <h1 className={styles.peopleTitle}>{title}</h1>
        <img src={image || '/people-rocks/default-male.svg'} className={styles.peopleImage}
             onMouseOver={(e) => {
                 onImageClick(e.target);
             }} alt={title}/>
        <Typography className={styles.peopleDescription}>{description}</Typography>
    </article>
}
